// import "tom-select/src/scss/tom-select.scss";
import '../css/main.css'
import BurgerPlugin from "./modules/burger";
import ScrollHeaderPlugin from "./modules/scroll-header";
import setScrollBarWidth from "set-scrollbar-width";
import TomSelect from 'tom-select';

type searchResult = {
	"link" : string,
	"title" : string,
}

const FWP_JSON = (window as any).FWP_JSON;

setScrollBarWidth();

// Використання плагіна
if (document.getElementById("burger")) {
	const burgerMenu = new BurgerPlugin(
		document.querySelectorAll(".burger-btn"),
		document.getElementById("mobile-menu"),
		{
			bodyClass : "opened-mobile-menu",
			overlay : document.getElementById("overlay-mobile-menu")
		}
	);
	const burgerSearch = new BurgerPlugin(
		document.querySelectorAll(".burger-search"),
		document.getElementById("searchForm"),
		{
			bodyClass : "opened-search",
			overlay : document.getElementById("overlay-search")
		}
	);
}
// Використання плагіна
const scrollHeader = new ScrollHeaderPlugin('header');

const search = new TomSelect('#search',{
	valueField: 'title',
	labelField: 'title',
	searchField: ['title'],
	create: true,
	maxOptions: 5,
	plugins: {
		'clear_button': {
			html : function(data: { className: any; title: any; }){
				return `<div id="ts-clear-button" class="${data.className}" title="${data.title}">
					<svg class="fill-current" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
						<rect x="2.55664" y="12.4463" width="14.076" height="1.5" rx="0.75" transform="rotate(-45 2.55664 12.4463)"/>
						<rect x="3.48975" y="2.49304" width="14.076" height="1.5" rx="0.75" transform="rotate(45 3.48975 2.49304)"/>
					</svg>
				</div>`;
			},
			title: "Очистити форму пошуку",
			className: "ts-clear-button"
		}
	},
	// fetch remote data
	load: function(query, callback) {
		var url = '/wp-json/facetwp/v1/fetch';
		fetch(url, {
			method: 'POST',
			headers: {
				"Content-Type": "application/json",
				"X-WP-Nonce": FWP_JSON.nonce,
			},
			body: JSON.stringify({
				"data": {
					"facets": {
						"autocompleate": [query]
					},
					"query_args": {
						"post_type": ["post", "page"],
						"posts_per_page": 4,
						"paged": 1
					}
				}
			})
		})
			.then(response => response.json())
			.then(json => {
				callback(json.results);
			}).catch(()=>{
				callback();
			});

	},
	// custom rendering function for options
	render: {
		option: function({link, title} : searchResult, escape : Function) {
			return `<div data-href="${ escape(link) }" class="ts-option">
				<p>${ escape(title) }</p>
				<svg class="stroke-current flex-none text-brand-blue" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
					<line x1="7.36302" y1="19.295" x2="15.9882" y2="11.9372" stroke-linecap="round"/>
					<path d="M15.5016 11.7641L7.63813 4.09906" stroke-linecap="round"/>
				</svg>
			</div>`;
		},
		item: function({link, title} : searchResult, escape : Function) {
			return `<div data-href="${ escape(link) }">
				<div class="truncate">${ escape(title) }</div>
			</div>`;
		},
		no_results: function () {
			return "";
		},
		option_create: function(data : any, escape : Function) {
			return '<div class="ts-create">Пошук <strong>' + escape(data.input) + '</strong>&hellip;</div>';
		},
		loading:function(){
			return `<div class="absolute inset-0 mt-1 flex justify-center items-center bg-white">
				<div class="animate-spin w-5 h-5 border-t border-r rounded-full border-brand-blue"></div>
			</div>`;
		},
	},
	onChange: function (value:string) {
		const link = this.getItem(value);
		window.location = link.dataset.href;
	},
	onInitialize: function () {
		const clearButton = document.getElementById('ts-clear-button') as HTMLInputElement;
		const searchInput = document.getElementById('search-ts-control') as HTMLInputElement;

		clearButton?.addEventListener('click', (e) => {
			searchInput.value = "";
		})

	}
});

document.getElementById('burgerSearchDesktop')?.addEventListener('click', setClientsSearch)
window.addEventListener('resize', setClientsSearch)

function setClientsSearch() {
	const searchForm = document.getElementById('searchForm');
	const searchButton = document.getElementById('burgerSearchDesktop');
	const clientRect = searchButton?.getBoundingClientRect();
	
	if (!clientRect || !searchForm) {
		return false;
	}

	if (window.innerWidth < 1024) {
		searchForm.style.left = '';
		searchForm.style.top = '';
		return true;
	}
	
	const x = clientRect.x - 12;
	const y = clientRect.y - 8;
	searchForm.style.left = x + 'px';
	searchForm.style.top = y + 'px';
	return true;
}

