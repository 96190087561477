class BurgerPlugin {
  constructor(elements, target, options) {
    this.options = options || {};
    this.burgerEl = elements;
    this.targetEl = target;
    this.htmlEl = document.getElementById("body");
    this.isOpen = false;

    // Додавання обробників подій
    this.burgerEl.forEach((el) =>
      el.addEventListener("click", this.toggleSidebar.bind(this))
    );
    this.options.overlay.addEventListener(
      "click",
      this.toggleSidebar.bind(this)
    );

    document
      .querySelectorAll(".go")
      ?.forEach((el) =>
        el.addEventListener("click", this.toggleSidebar.bind(this))
      );

    document.addEventListener("keyup", this.onKeyUp.bind(this));
  }

  toggleSidebar(event) {
    event.preventDefault();
    this.htmlEl.classList.toggle(this.options.bodyClass);
    this.targetEl.classList.toggle("opened");

    // Оновлення значень атрибутів ARIA
    if (this.htmlEl.classList.contains(this.options.bodyClass)) {
      this.isOpen = true;
      this.triggerEvent("burger-opened");
      this.targetEl.removeAttribute("aria-hidden");
    } else {
      this.isOpen = false;
      this.triggerEvent("burger-closed");
    }
  }

  onKeyUp(event) {
    if (event.keyCode === 27 && this.isOpen) {
      this.toggleSidebar(event);
    }
  }

  triggerEvent(eventName) {
    const event = new Event(eventName);
    document.dispatchEvent(event);
  }
}
export default BurgerPlugin;
