class ScrollHeaderPlugin {
  constructor(selector) {
    this.header = document.querySelector(selector);
    this.lastScrollPosition = 0;
    this.update = this.update.bind(this);
    this.init();
  }

  init() {
    this.update();
  }

  destroy() {
    cancelAnimationFrame(this.animationFrameId);
  }

  update() {
    const currentScrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;

    const secontHeaderHeight =
      document.getElementById("second-header").clientHeight;

    if (currentScrollPosition > secontHeaderHeight) {
      this.header.classList.add("scrolled");
      document.getElementById("burger").classList.add("!block");
    } else {
      this.header.classList.remove("scrolled");
      document.getElementById("burger").classList.remove("!block");
    }

    this.lastScrollPosition = currentScrollPosition;
    this.animationFrameId = requestAnimationFrame(this.update);
  }
}

export default ScrollHeaderPlugin;
